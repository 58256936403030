/* import __COLOCATED_TEMPLATE__ from './action-list.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    actionLinks: {
      url: string;
      name: string;
    }[];
  };
  Element: HTMLUListElement;
  Blocks: {
    default: [];
  };
}

const AnswerNoticeActionListComponent = templateOnlyComponent<Signature>();
export default AnswerNoticeActionListComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::AnswerNotice::ActionList': typeof AnswerNoticeActionListComponent;
    'ai-agent/playground/fin-answer/answer-notice/action-list': typeof AnswerNoticeActionListComponent;
  }
}
