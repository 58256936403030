/* import __COLOCATED_TEMPLATE__ from './source-item.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface SourceItem {
  id: string;
  type: 'personality' | 'content' | 'guidance' | 'real-time-translation';
  icon: InterfaceIconName;
  title: string;
  isDeleted?: boolean;
  link?: {
    route: string;
    query?: Record<string, unknown>;
    models?: string[];
  };
}

interface Signature {
  Args: {
    item: SourceItem;
  };
}

export default class SourceItemComponent extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  trackSourceClick = () => {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'source_item',
      section: 'inspect_sources',
      source_type: this.args.item.type,
      source_title: this.args.item.title,
    });
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::SourceItem': typeof SourceItemComponent;
    'ai-agent/source-item': typeof SourceItemComponent;
  }
}
