/* import __COLOCATED_TEMPLATE__ from './action-link.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    actionLink: {
      url: string;
      name: string;
    };
    translationKey: string;
  };
  Element: HTMLAnchorElement;
  Blocks: {
    default: [];
  };
}

const AnswerNoticeActionLinkComponent = templateOnlyComponent<Signature>();
export default AnswerNoticeActionLinkComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::AnswerNotice::ActionLink': typeof AnswerNoticeActionLinkComponent;
    'ai-agent/playground/fin-answer/answer-notice/action-link': typeof AnswerNoticeActionLinkComponent;
  }
}
