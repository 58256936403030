/* import __COLOCATED_TEMPLATE__ from './action.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import Component from '@glimmer/component';
import { type ActionPlan } from 'embercom/lib/fin-playground/action-plan';

interface ActionAnswerNoticeComponentSignature {
  Args: {
    actionPlan: ActionPlan;
  };
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

export default class ActionAnswerNoticeComponent extends Component<ActionAnswerNoticeComponentSignature> {
  @service declare router: RouterService;
  @service declare appService: any;

  get actionLinks(): { url: string; name: string }[] {
    return this.args.actionPlan.steps.map((step) => ({
      url: this.router.urlFor(
        'apps.app.settings.app-settings.custom-actions.custom-action',
        this.appService.app.id,
        step.id,
      ),
      name: step.name,
    }));
  }

  get firstActionLink(): { url: string; name: string } {
    return this.actionLinks[0];
  }

  get multipleStepsTranslationKey(): string {
    return this.args.actionPlan.requiresOtpAuth
      ? 'ai-agent.playground.answer.notice.content.action.otp-multiple'
      : 'ai-agent.playground.answer.notice.content.action.multiple';
  }

  get singleStepTranslationKey(): string {
    return this.args.actionPlan.requiresOtpAuth
      ? 'ai-agent.playground.answer.notice.content.action.otp-single'
      : 'ai-agent.playground.answer.notice.content.action.single';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::AnswerNotice::Action': typeof ActionAnswerNoticeComponent;
    'ai-agent/playground/fin-answer/answer-notice/action': typeof ActionAnswerNoticeComponent;
  }
}
