/* import __COLOCATED_TEMPLATE__ from './answer-notice.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent-2 */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
}

const AnswerNoticeComponent = templateOnlyComponent<Signature>();
export default AnswerNoticeComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Playground::FinAnswer::AnswerNotice': typeof AnswerNoticeComponent;
    'ai-agent/playground/fin-answer/answer-notice': typeof AnswerNoticeComponent;
  }
}
